<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('registration_dates')"
                        :isNewButton="checkPermission('registrationdate_store')"
                        @new-button-click="createForm"
                        @filter-div-status="datatable.filterStatus = $event">
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('registration_dates')"
                              :isNewButton="checkPermission('registrationdate_store')"
                              @new-button-click="createForm"
                              @filter-div-status="datatable.filterStatus = $event">
                </HeaderMobile>
            </template>
            <datatable-filter v-show="datatable.filterStatus" @filter="filter" @filterClear="filterClear">
                <b-row>
                    <b-col sm="12" md="9" lg="3">
                        <b-form-group :label="$t('registration_type')">
                            <parameter-selectbox v-model="datatable.queryParams.filter.registration_type" code="registration_types"/>
                        </b-form-group>
                    </b-col>
                </b-row>
            </datatable-filter>
            <datatable :isLoading.sync="datatable.isLoading" :columns="datatable.columns" :rows="datatable.rows"
                       :total="datatable.total" :queryParams="datatable.queryParams" :lineNumbers="false"
                       @on-page-change="onPageChange" @on-sort-change="onSortChange"
                       @on-per-page-change="onPerPageChange"
                       v-show="datatable.showTable">
            </datatable>
            <CommonModal ref="modal" size="md" :onHideOnlyX="true" @bHideModalHeaderClose="formClear">
                <template v-slot:CommonModalTitle>
                    <div>{{ process_title == 'create' ? $t("add") : '' }}</div>
                    <div>{{ process_title == 'edit' ? $t("edit") : '' }}</div>
                    <div>{{ process_title == 'show' ? $t("show") : '' }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <create v-if="process_title==='create'" @success="successProcess"/>
                    <update v-if="process_title==='edit'" :id="id" @success="successProcess"/>
                    <show v-if="process_title==='show'" :id="id"/>
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>
<script>
import AppLayout from "@/layouts/AppLayout";
import Header from "@/layouts/AppLayout/Header"
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile.vue";
import DatatableFilter from "@/components/datatable/DatatableFilter";
import Datatable from "@/components/datatable/Datatable";
import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox.vue";

import CommonModal from "@/components/elements/CommonModal";

import RegistrationDateService from "@/services/RegistrationDateService";

import Create from "./Create.vue";
import Update from "./Update.vue"
import Show from "./Show.vue"


import qs from "qs";


export default {
    components: {
        AppLayout,
        Header,
        HeaderMobile,
        DatatableFilter,
        Datatable,
        ParameterSelectbox,

        Create,
        Update,
        Show,

        CommonModal,
    },
    metaInfo() {
        return {
            title: this.$t("registration_dates"),
        };
    },
    data() {
        return {
            id: null,
            process_title: null,
            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: "",
                        field: "buttons",
                        html: true,
                        sortable: false,
                        tdClass: "p-0 text-center w-40 align-middle",
                        buttons: [
                            {
                                text: this.$t("edit"),
                                class: "ri-edit-box-line",
                                permissions: "registrationdate_update",
                                callback: ({id}) => {
                                    this.id = id
                                    this.updateForm();
                                },
                            },
                            {
                                text: this.$t("show"),
                                class: "ri-eye-line",
                                permissions: "registrationdate_show",
                                callback: ({id}) => {
                                    this.id = id
                                    this.showForm();
                                },
                            },
                            {
                                text: this.$t("delete"),
                                class: "ri-delete-bin-2-line",
                                permissions: "registrationdate_delete",
                                callback: ({id}) => {
                                    this.del(id);
                                },
                            },
                        ],
                    },
                    {
                        label: this.$t("registration_type_code"),
                        field: "registration_type",
                        sortable: false,
                    },
                    {
                        label: this.$t("registration_type"),
                        field: this.getLocaleField("registration_type_name"),
                        sortable: false,
                    },
                    {
                        label: this.$t("start_date"),
                        field: "start_date",
                        sortable: false,
                    },
                    {
                        label: this.$t("end_date"),
                        field: "end_date",
                        sortable: true,
                    },
                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    filter: {},
                    sort: "id",
                    page: 1,
                    limit: 20,
                },
            },
        };
    },
    methods: {
        filter() {
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        filterClear() {
            this.datatable.queryParams.filter = {};
            this.getRows();
        },
        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
            this.getRows();
        },
        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        onSortChange(params) {
            const sortType = params[0].type == "desc" ? "-" : "";
            this.datatable.queryParams.sort = sortType + params[0].field;
            this.getRows();
        },
        getRows() {
            this.datatable.showTable = true;
            this.datatable.isLoading = true;

            const config = {
                params: {
                    ...this.datatable.queryParams,
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false}),
            };

            return RegistrationDateService.getAll(config)
                .then((response) => {
                    this.datatable.rows = response.data.data;
                    this.datatable.total = response.data.pagination.total;
                })
                .finally(() => {
                    this.datatable.isLoading = false;
                });
        },
        showForm() {
            this.process_title = 'show'
            this.showModal()
        },
        updateForm() {
            this.process_title = 'edit'
            this.showModal()
        },
        createForm() {
            this.process_title = 'create'
            this.showModal()
        },
        del(id) {
            this.$swal.fire({
                text: this.$t('are_you_sure_to_delete'),
                showCancelButton: true,
                confirmButtonText: this.$t('yes'),
                cancelButtonText: this.$t('no'),
            }).then((result) => {
                if (result.isConfirmed) {
                    RegistrationDateService.deleteRegistrationDate(id)
                        .then((response) => {
                            this.filter();
                            this.$toast.success(this.$t("api." + response.data.message));
                        })
                        .catch(err => {
                            this.showErrors(err)
                        });
                }
            })
        },
        showModal() {
            this.$refs.modal.$refs.commonModal.show();
        },
        hideModal() {
            this.$refs.modal.$refs.commonModal.hide();
        },
        successProcess() {
            this.process_title = null;
            this.getRows();
            this.hideModal();
        },
        formClear() {
            this.process_title = null;
            this.hideModal()
        }
    },
};
</script>