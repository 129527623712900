<template>
    <div class="mb-5 align-items-center">
        <ValidationObserver ref="storeForm">
            <div class="row">
                <div class="col-12">
                    <ValidationProvider name="type" rules="" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('type')">
                            <parameter-selectbox code="registration_types" :validate-error="errors[0]"
                                                 v-model="form.registration_type"/>
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-12">
                    <ValidationProvider name="start_date" rules="required" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('start_date')">
                            <select-date :is-time="true" v-model="form.start_date" :validation-error="errors[0]" :time="true" @input="form.start_date = toLocaleTimeDate($event)"/>
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-12">
                    <ValidationProvider name="end_date" rules="required" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('end_date')">
                            <select-date :is-time="true" v-model="form.end_date" :validation-error="errors[0]" :time="true" @input="form.end_date = toLocaleTimeDate($event)"/>
                        </b-form-group>
                    </ValidationProvider>
                </div>
            </div>
        </ValidationObserver>
        <div class="col-12 mt-3 d-flex p-0">
            <b-button @click="update" type="button" variant="primary" class="btn-lg mr-2">
                {{ $t("update") | toUpperCase }}
            </b-button>
        </div>
    </div>
</template>

<script>


import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox.vue";
import SelectDate from "@/components/interactive-fields/SelectDate.vue";

import {ValidationObserver, ValidationProvider} from "vee-validate";

import RegistrationDateService from "@/services/RegistrationDateService";

export default {
    components:{
        ValidationProvider,
        ValidationObserver,

        ParameterSelectbox,
        SelectDate,
    },
    props:{
        id:{
            type:Number,
            default:null
        }
    },
    data() {
        return {
            form: {},
        }
    },
    created() {
        this.show()
    },
    methods: {
        show(){
            RegistrationDateService.get(this.id)
                .then((response) => {
                    this.form = response.data.data;
                    if(this.form.student_number) {
                        this.form.student_number = this.form.student_number.join("\n")
                    }
                })
                .catch((error) => {
                    if (error.data.message) {
                        this.$toast.error(this.$t("api." + error.data.message));
                    }
                });
        },
        async update() {
                let formData = {...this.form};

                RegistrationDateService.update(this.id, formData)
                    .then((response) => {
                        this.$emit('success')
                        this.$toast.success(this.$t("api." + response.data.message));
                    })
                    .catch((error) => {
                        this.showErrors(error,this.$refs.storeForm)
                    })
        }
    }
}
</script>