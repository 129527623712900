<template>
    <div class="mb-5 align-items-center">
            <ValidationObserver ref="storeForm">
                <div class="row">
                    <div class="col-12">
                        <ValidationProvider name="registration_type" rules="" v-slot="{ valid, errors }">
                            <b-form-group :label="$t('registration_type')">
                                <parameter-selectbox code="registration_types" :validate-error="errors[0]"
                                                     v-model="form.registration_type"/>
                            </b-form-group>
                        </ValidationProvider>
                    </div>
                    <div class="col-12">
                        <ValidationProvider name="start_date" rules="required" v-slot="{ valid, errors }">
                            <b-form-group :label="$t('start_date')">
                                <select-date :is-time="true" v-model="form.start_date" :validation-error="errors[0]" :time="true" @input="form.start_date = toLocaleTimeDate($event)"/>
                            </b-form-group>
                        </ValidationProvider>
                    </div>
                    <div class="col-12">
                        <ValidationProvider name="end_date" rules="required" v-slot="{ valid, errors }">
                            <b-form-group :label="$t('end_date')"> 
                                <select-date :is-time="true" v-model="form.end_date" :validation-error="errors[0]" :time="true" @input="form.end_date = toLocaleTimeDate($event)"/>
                            </b-form-group>
                        </ValidationProvider>
                    </div>
                </div>
            </ValidationObserver>
        <div class="col-12 mt-3 d-flex p-0">
            <b-button @click="store" type="button" variant="primary" class="btn-lg mr-2">
                {{ $t("save") | toUpperCase }}
            </b-button>
        </div>
    </div>
</template>

<script>
import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox.vue";
import SelectDate from "@/components/interactive-fields/SelectDate.vue";

import {ValidationObserver, ValidationProvider} from "vee-validate";

import RegistrationDateService from "@/services/RegistrationDateService";

export default{
    components:{

        ValidationProvider,
        ValidationObserver,
        ParameterSelectbox,
        SelectDate,
    },
    data(){
        return{
            form: {},
        }
    },
    methods: {
        async store() {
            const isValid = await this.$refs.storeForm.validate();
            if (isValid) {
                let formData = {...this.form};

                    RegistrationDateService.store(formData)
                        .then((response) => {
                            this.$emit('success')
                            this.$toast.success(this.$t("api." + response.data.message));
                        })
                        .catch((error) => {
                            this.showErrors(error,this.$refs.storeForm)
                        })
            }
        }
    }
}
</script>